import React from 'react';

/**
 *  props.data => [['USDTRY', 'KGRUSD'], [en ucuz (USDTRY) firma adı, bu firmanın fiyatı], [en ucuz (KGRUSD) firma adı, bu firmanın fiyatı]]
 */
function TableForTransform(props) {
    return (
        <div className="table-responsive">
            <table className="table shadow table-dark table-hover">
                <thead>
                    <tr>
                        <th>Tür</th>
                        <th>Firma</th>
                        <th>Değer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {props.data[0][0]}
                        </td>
                        <td>
                            {props.data[1][0]}
                        </td>
                        <td>
                            {parseFloat(props.data[1][1]).toFixed(4)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {props.data[0][1]}
                        </td>
                        <td>
                            {props.data[2][0]}
                        </td>
                        <td>
                            {parseFloat(props.data[2][1]).toLocaleString('tr')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Toplam
                        </td>
                        <td colSpan='2'>
                            <div className="w-100 text-center">{(props.data[1][1] * props.data[2][1]).toLocaleString('tr') + " / 995 = " + ((props.data[1][1] * props.data[2][1]) / 995).toLocaleString('tr') }</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default TableForTransform;

export const api = async (url, method, getType) =>  {
	try {
		const response = await fetch(url, {
			method: method,
			//body: JSON.stringify(body),
			// headers: {
			// 	"Content-type": "application/json; charset=UTF-8",
			// },
        });

		if (response.ok) {
            if (getType === 'json') {
                let data = await response.json();
                return data;
            } else if (getType === 'text') {
                let data = response.text();
                return data;
            }
		} else {
			console.warn(response);
			//alert("Sunucu ile bağlantı kurulamadı. Lütfen internet bağlantınızı kontrol ederek tekrar deneyin.");
			return false;
		}
	} catch (error) {
		console.warn(error);
		//alert("Bilinmeyen bir hata ile karşılaşıldı. Lütfen tekrar deneyiniz. Hatanın devam etmesi durumunda yetkililer ile görüşünüz.");
		return false;
	}
}

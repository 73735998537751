import React from 'react';
import MainTable from './MainTable';
import TableForTransform from './TableForTransform';

function Card(props) {
    return (
        <div className="col-12 col-lg-6 col-xl-4 py-3">
            <div className="text-center">
                <h3>{props.title}</h3>
            </div>

            {
                props.type === 'MainTable'
            
            ?
            <MainTable data={props.data} />
            :
            <TableForTransform data={props.data} />
            }
            
        </div>
    );
}

export default Card;

import React from 'react';
import DataTable from './DataTable';

function MainTable(props) {
    return (
        <div className="table-responsive">
            <table className="table shadow table-dark">
                <thead>
                    <tr>
                        <th className="text-center">Alış</th>
                        <th className="text-center">Satış</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <DataTable 
                                data={props.data[0]}
                                // setMinValueFunction={props.setMinValueFunction} 
                                type="alis"
                            />
                        </td>
                        <td>
                            <DataTable
                                data={props.data[1]}
                                // setMinValueFunction={props.setMinValueFunction}
                                type="satis"
                            />  
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default MainTable;

import React, {useState, useEffect} from 'react';
import './css/index.css';
import Card from './components/Card';

import {api} from './functions/api';

function App() {
    // const [DolarTl, setDolarTl] = useState({alis: {Ozbey: 0, Rona: 0}, satis: {Ozbey: 0, Rona: 0}});
    // const [EuroTl, setEuroTl] = useState({alis: {Ozbey: 0, Rona: 0}, satis: {Ozbey: 0, Rona: 0}});
    // const [EuroDolar, setEuroDolar] = useState({alis: {Ozbey: 0, Rona: 0}, satis: {Ozbey: 0, Rona: 0}});
    // const [AltınTl, setAltınTl] = useState({alis: {}, satis: {}});
    const [OzbeyAlDolarTl, setOzbeyAlDolarTl] = useState(0), [OzbeyAlEuroTl, setOzbeyAlEuroTl] = useState(0), [OzbeyAlEuroDolar, setOzbeyAlEuroDolar] = useState(0),
    // [OzbeyAlCHFTRY, setOzbeyAlCHFTRY] = useState(0), [OzbeyAlGBPTRY, setOzbeyAlGBPTRY] = useState(0),
    [OzbeyAlAltinTL, setOzbeyAlAltinTL] = useState(0), [OzbeyAlAltinDolar, setOzbeyAlAltinDolar] = useState(0), 
    // [OzbeyAlAltinEuro, setOzbeyAlAltinEuro] = useState(0),
    
    [OzbeySatDolarTl, setOzbeySatDolarTl] = useState(0), [OzbeySatEuroTl, setOzbeySatEuroTl] = useState(0), [OzbeySatEuroDolar, setOzbeySatEuroDolar] = useState(0),
    // [OzbeySatCHFTRY, setOzbeySatCHFTRY] = useState(0), [OzbeySatGBPTRY, setOzbeySatGBPTRY] = useState(0),
    [OzbeySatAltinTL, setOzbeySatAltinTL] = useState(0), [OzbeySatAltinDolar, setOzbeySatAltinDolar] = useState(0), 
    // [OzbeySatAltinEuro, setOzbeySatAltinEuro] = useState(0),
    
    [RonaAlDolarTl, setRonaAlDolarTl] = useState(0), [RonaAlEuroTl, setRonaAlEuroTl] = useState(0), [RonaAlEuroDolar, setRonaAlEuroDolar] = useState(0),
    // [RonaAlCHFTRY, setRonaAlCHFTRY] = useState(0), [RonaAlGBPTRY, setRonaAlGBPTRY] = useState(0),
    [RonaAlAltinTL, setRonaAlAltinTL] = useState(0), [RonaAlAltinDolar, setRonaAlAltinDolar] = useState(0), [RonaAlAltinEuro, setRonaAlAltinEuro] = useState(0),
    
    [RonaSatDolarTl, setRonaSatDolarTl] = useState(0), [RonaSatEuroTl, setRonaSatEuroTl] = useState(0), [RonaSatEuroDolar, setRonaSatEuroDolar] = useState(0),
    // [RonaSatCHFTRY, setRonaSatCHFTRY] = useState(0), [RonaSatGBPTRY, setRonaSatGBPTRY] = useState(0),
    [RonaSatAltinTL, setRonaSatAltinTL] = useState(0), [RonaSatAltinDolar, setRonaSatAltinDolar] = useState(0), [RonaSatAltinEuro, setRonaSatAltinEuro] = useState(0),
    
    [AhlatAlDolarTl, setAhlatAlDolarTl] = useState(0), [AhlatAlEuroTl, setAhlatAlEuroTl] = useState(0), [AhlatAlEuroDolar, setAhlatAlEuroDolar] = useState(0),
    [AhlatAlCHFTRY, setAhlatAlCHFTRY] = useState(0), [AhlatAlGBPTRY, setAhlatAlGBPTRY] = useState(0),
    [AhlatAlAltinTL, setAhlatAlAltinTL] = useState(0), 
    // [AhlatAlAltinDolar, setAhlatAlAltinDolar] = useState(0), [AhlatAlAltinEuro, setAhlatAlAltinEuro] = useState(0),

    [AhlatSatDolarTl, setAhlatSatDolarTl] = useState(0), [AhlatSatEuroTl, setAhlatSatEuroTl] = useState(0), [AhlatSatEuroDolar, setAhlatSatEuroDolar] = useState(0),
    [AhlatSatCHFTRY, setAhlatSatCHFTRY] = useState(0), [AhlatSatGBPTRY, setAhlatSatGBPTRY] = useState(0),
    [AhlatSatAltinTL, setAhlatSatAltinTL] = useState(0), 
    // [AhlatSatAltinDolar, setAhlatSatAltinDolar] = useState(0), [AhlatSatAltinEuro, setAhlatSatAltinEuro] = useState(0),

    [HaremAlDolarTl, setHaremAlDolarTl] = useState(0), [HaremAlEuroTl, setHaremAlEuroTl] = useState(0), [HaremAlEuroDolar, setHaremAlEuroDolar] = useState(0),
    [HaremAlCHFTRY, setHaremAlCHFTRY] = useState(0), [HaremAlGBPTRY, setHaremAlGBPTRY] = useState(0),
    [HaremAlAltinTL, setHaremAlAltinTL] = useState(0), [HaremAlAltinDolar, setHaremAlAltinDolar] = useState(0), [HaremAlAltinEuro, setHaremAlAltinEuro] = useState(0),

    [HaremSatDolarTl, setHaremSatDolarTl] = useState(0), [HaremSatEuroTl, setHaremSatEuroTl] = useState(0), [HaremSatEuroDolar, setHaremSatEuroDolar] = useState(0),
    [HaremSatCHFTRY, setHaremSatCHFTRY] = useState(0), [HaremSatGBPTRY, setHaremSatGBPTRY] = useState(0),
    [HaremSatAltinTL, setHaremSatAltinTL] = useState(0), [HaremSatAltinDolar, setHaremSatAltinDolar] = useState(0), [HaremSatAltinEuro, setHaremSatAltinEuro] = useState(0);

    // const [MinUSDTRY, setMinUSDTRY] = useState(0), [MinKGRUSD, setMinKGRUSD] = useState(0), [MinEURTRY, setMinEURTRY] = useState(0), [MinKGREUR, setMinKGREUR] = useState(0);

    const [ResultTRYtoUSDtoKGR, setResultTRYtoUSDtoKGR] = useState(["-", "-", "-"]), [ResultTRYtoEURtoKGR, setResultTRYtoEURtoKGR] = useState(["-", "-", "-"]);

    const [OzbeyUpdateTime, setOzbeyUpdateTime] = useState("00:00:0000"), [RonaUpdateTime, setRonaUpdateTime] = useState("00:00:0000"), [AhlatUpdateTime, setAhlatUpdateTime] = useState("00:00:0000"),
    [HaremUpdateTime, setHaremUpdateTime] = useState("00:00:0000");


    const ozbeyApi = async () => {
        const response = await api('http://sebatborsa.com/ozbey', 'GET', 'json');
        if (response) {
            let is_exist_eurtry = false;
            var alDolarTl, alEuroDolar, satDolarTl, satEuroDolar;

            setOzbeyUpdateTime(response.date.replace('/', '.') + " " + response.time);

            response.prices.map((element, index) => {
                if (element.code === 'USDTRY') {
                    alDolarTl = parseFloat(element.al.replace(',', '.'));
                    satDolarTl = parseFloat(element.sat.replace(',', '.'));
                    setOzbeyAlDolarTl(alDolarTl);
                    setOzbeySatDolarTl(satDolarTl);
                } else if (element.code === 'EURTRY') {
                    is_exist_eurtry = true;
                    setOzbeyAlEuroTl(parseFloat(element.al.replace(',', '.')));
                    setOzbeySatEuroTl(parseFloat(element.sat.replace(',', '.')));
                } else if (element.code === 'EURUSD') {
                    // gelen verilerde olmadığı için hesaplama yapıldı
                    alEuroDolar = parseFloat(element.al.replace(',', '.'));
                    satEuroDolar = parseFloat(element.sat.replace(',', '.'));
                    setOzbeyAlEuroDolar(alEuroDolar);
                    setOzbeySatEuroDolar(satEuroDolar);
                } else if (element.code === 'KGRTRY') {
                    // biz gram istiyoruz bu sebeple
                    setOzbeyAlAltinTL(parseFloat(element.al.replace(',', '.')));
                    setOzbeySatAltinTL(parseFloat(element.sat.replace(',', '.')));
                } else if (element.code === 'KGRUSD') {
                    setOzbeyAlAltinDolar(parseFloat(element.al.replace(',', '.')));
                    setOzbeySatAltinDolar(parseFloat(element.sat.replace(',', '.')));
                }
                //  else if (element.code === 'KGREUR') {
                //     setOzbeyAlAltinEuro(parseFloat(element.al.replace(',', '.')));
                //     setOzbeySatAltinEuro(parseFloat(element.sat.replace(',', '.')));
                // }
            });
            if (! is_exist_eurtry) {
                setOzbeyAlEuroTl((alDolarTl * alEuroDolar));
                setOzbeySatEuroTl((satDolarTl * satEuroDolar));
            }
        } else {
            console.log('HATA: özbey');
        }
    }

    const ahlatApi = async () => {
        const response = await api('http://sebatborsa.com/ahlatci', 'GET', 'text');
        if (response) {

            let beginTimeData = response.search('<span id=\"lblZaman\">');
            
            // aşağıdaki beginData aynı zamanda yukarıdaki beginTimeData nın lastTimeData sına eşittir.
            let beginData = response.search('<Table Class=\"table table-striped\">');
            let lastData = response.search('</tbody></table>');

            // TIME
            let rawTimeData = response.slice(beginTimeData, beginData);
            let timeData = rawTimeData.replace(/<[^>]*>?/gm, '').trim()

            setAhlatUpdateTime(timeData.split(': ')[1]);

            // DATA
            let rawDataTable = response.slice(beginData, lastData);

            let rawDataArray = rawDataTable.split("<tr>");

            rawDataArray.shift();
            rawDataArray.shift();

            rawDataArray.map((item, index) => {
                rawDataArray[index] = item.replace(/<[^>]*>?/gm, '').trim().split(' ');
                if (rawDataArray[index][0] === 'USD') {
                    setAhlatAlDolarTl(parseFloat(rawDataArray[index][1].replace(',', '.')));
                    setAhlatSatDolarTl(parseFloat(rawDataArray[index][2].replace(',', '.')));
                } else if (rawDataArray[index][0] === 'EUR') {
                    setAhlatAlEuroTl(parseFloat(rawDataArray[index][1].replace(',', '.')));
                    setAhlatSatEuroTl(parseFloat(rawDataArray[index][2].replace(',', '.')));
                } else if (rawDataArray[index][0] === 'EURUSD') {
                    setAhlatAlEuroDolar(parseFloat(rawDataArray[index][1].replace(',', '.')));
                    setAhlatSatEuroDolar(parseFloat(rawDataArray[index][2].replace(',', '.')));
                } else if (rawDataArray[index][0] === 'CHF') {
                    setAhlatAlCHFTRY(parseFloat(rawDataArray[index][1].replace(',', '.')));
                    setAhlatSatCHFTRY(parseFloat(rawDataArray[index][2].replace(',', '.')));
                } else if (rawDataArray[index][0] === 'GBP') {
                    setAhlatAlGBPTRY(parseFloat(rawDataArray[index][1].replace(',', '.')));
                    setAhlatSatGBPTRY(parseFloat(rawDataArray[index][2].replace(',', '.')));
                } else if (rawDataArray[index][0] === 'XAU') {
                    setAhlatAlAltinTL((parseFloat(rawDataArray[index][1].replace(',', '.')) * 1000));
                    setAhlatSatAltinTL((parseFloat(rawDataArray[index][2].replace(',', '.')) * 1000));
                } 
                // else if (rawDataArray[index][0] === 'XAUUSD') {
                //     setAhlatAlAltinDolar(parseFloat(rawDataArray[index][1].replace(',', '.')));
                //     setAhlatSatAltinDolar(parseFloat(rawDataArray[index][2].replace(',', '.')));
                // }
            });

        } else {
            console.log('HATA: ahlat');
        }
    }

    const ronaWebSocket = () => {
        const wsUri = "ws://ronadoviz.aifafx.com:8088";
        const websocket = new WebSocket(wsUri);
    
        websocket.onopen = (evt) => {
            console.log("Rona WebSocket Bağlandı");
            websocket.send("Hello server");
        }
        websocket.onclose = (evt) => {
            console.log("Rona WebSocket Kapandı");
        }
        websocket.onerror = (evt) => {
            console.log("Rona WebSocket Hata");
        }
        websocket.onmessage = (socketMessage) => {
            let strData = socketMessage.data.replace(/^[\u0080-\uffff]/g, "");
            strData = strData.replace("\n\n", "\n");
    
            let arrayData = strData.split("\n");
            arrayData.pop();  //sondaki fazlalığı siliyoruz
            
            setRonaUpdateTime(arrayData[0].split(';')[5]);

            arrayData.map((item) => {
                let data = item.split(";");
                // console.log(data);
                if (data[0] === 'USDTRY') {
                    setRonaAlDolarTl(parseFloat(data[3]));
                    setRonaSatDolarTl(parseFloat(data[4]));
                } else if (data[0] === 'EURTRY') {
                    setRonaAlEuroTl(parseFloat(data[3]));
                    setRonaSatEuroTl(parseFloat(data[4]));
                } else if (data[0] === 'EURUSD') {
                    setRonaAlEuroDolar(parseFloat(data[3]));
                    setRonaSatEuroDolar(parseFloat(data[4]));
                } else if (data[0] === 'GAUTRY') {
                    setRonaAlAltinTL((parseFloat(data[3]) * 1000));
                    setRonaSatAltinTL((parseFloat(data[4]) * 1000));
                } else if (data[0] === 'GAUUSD') {
                    setRonaAlAltinDolar((parseFloat(data[3]) * 1000));
                    setRonaSatAltinDolar((parseFloat(data[4]) * 1000));
                } else if (data[0] === 'GAUEUR') {
                    setRonaAlAltinEuro((parseFloat(data[3]) * 1000));
                    setRonaSatAltinEuro((parseFloat(data[4]) * 1000));
                }
            });
        }
    
    }

    const cihanSoapService = () => {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.open('POST', 'https://somesoapurl.com/', true);

        // build SOAP request
        var sr =
            '<?xml version="1.0" encoding="utf-8"?>' +
            '<soapenv:Envelope ' + 
                'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
                'xmlns:api="http://127.0.0.1/Integrics/Enswitch/API" ' +
                'xmlns:xsd="http://www.w3.org/2001/XMLSchema" ' +
                'xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">' +
                '<soapenv:Body>' +
                    '<api:some_api_call soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">' +
                        '<username xsi:type="xsd:string">login_username</username>' +
                        '<password xsi:type="xsd:string">password</password>' +
                    '</api:some_api_call>' +
                '</soapenv:Body>' +
            '</soapenv:Envelope>';

        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState == 4) {
                if (xmlhttp.status == 200) {
                    alert(xmlhttp.responseText);
                    // alert('done. use firebug/console to see network response');
                }
            }
        }
        // Send the POST request
        xmlhttp.setRequestHeader('Content-Type', 'text/xml');
        xmlhttp.send(sr);
        // send request
        // ...
    }

    const harem = async () => {
        const response = await api('http://sebatborsa.com/harem', 'GET', 'json');
        if (response) {
            setHaremUpdateTime(new Date(response.meta.time).toLocaleString('tr'));

            setHaremAlDolarTl(parseFloat(response.data.USDTRY.alis));
            setHaremSatDolarTl(parseFloat(response.data.USDTRY.satis));

            setHaremAlEuroTl(parseFloat(response.data.EURTRY.alis));
            setHaremSatEuroTl(parseFloat(response.data.EURTRY.satis));

            setHaremAlEuroDolar(parseFloat(response.data.EURUSD.alis));
            setHaremSatEuroDolar(parseFloat(response.data.EURUSD.satis));

            setHaremAlCHFTRY(parseFloat(response.data.CHFTRY.alis));
            setHaremSatCHFTRY(parseFloat(response.data.CHFTRY.satis));

            setHaremAlGBPTRY(parseFloat(response.data.GBPTRY.alis));
            setHaremSatGBPTRY(parseFloat(response.data.GBPTRY.satis));

            setHaremAlAltinTL((parseFloat(response.data.ALTIN.alis) * 1000));
            setHaremSatAltinTL((parseFloat(response.data.ALTIN.satis) * 1000));

            setHaremAlAltinDolar(parseFloat(response.data.USDKG.alis));
            setHaremSatAltinDolar(parseFloat(response.data.USDKG.satis));

            setHaremAlAltinEuro(parseFloat(response.data.EURKG.alis));
            setHaremSatAltinEuro(parseFloat(response.data.EURKG.satis));
        } else {
            console.log('HATA: harem');
        }
    }

    useEffect(() => {
        const TRYtoUSDtoKGR = () => {
            let minUsdTry = [['Ozbey', OzbeyAlDolarTl], ['Rona', RonaSatDolarTl], ['Ahlat', AhlatSatDolarTl], ['Harem', HaremSatDolarTl]];
            let minKgrUsd = [['Ozbey', OzbeyAlAltinDolar], ['Rona', RonaSatAltinDolar], ['Harem', HaremSatAltinDolar]];
            
            minUsdTry.sort(function(a, b){return a[1] - b[1]});
            minKgrUsd.sort(function(a, b){return a[1] - b[1]});

            setResultTRYtoUSDtoKGR([['USDTRY', 'KGRUSD'], minUsdTry[0], minKgrUsd[0]]);
        }

        return TRYtoUSDtoKGR;
    }, [OzbeyAlDolarTl, RonaSatDolarTl, AhlatSatDolarTl, HaremSatDolarTl, OzbeyAlAltinDolar, RonaSatAltinDolar, HaremSatAltinDolar])

    useEffect(() => {
        const TRYtoEURtoKGR = () => {
            let minEurTry = [['Ozbey', OzbeyAlEuroTl], ['Rona', RonaSatEuroTl], ['Ahlat', AhlatSatEuroTl], ['Harem', HaremSatEuroTl]];
            let minKgrEur = [['Rona', RonaSatAltinEuro], ['Harem', HaremSatAltinEuro]];

            minEurTry.sort(function(a, b){return a[1] - b[1]});
            minKgrEur.sort(function(a, b){return a[1] - b[1]});

            setResultTRYtoEURtoKGR([['EURTRY', 'KGREUR'], minEurTry[0], minKgrEur[0]]);
        }

        return TRYtoEURtoKGR;
    }, [OzbeyAlEuroTl, RonaSatEuroTl, AhlatSatEuroTl, HaremSatEuroTl, RonaSatAltinEuro, HaremSatAltinEuro])


    const getData = () => {
        setInterval(()=> {
            ozbeyApi();
        }, 5000);

        ronaWebSocket();

        setInterval(()=> {
            ahlatApi();
        }, 5000);

        setInterval(()=> {
            harem();
        }, 5000);
    }

    useEffect(() => {
        const getDataFunc = getData();

        return getDataFunc;
    }, [])

    return (
        <main className="App">
            <div className="container-fluid row">
                <div className="col-3 text-center">
                    Özbey: {OzbeyUpdateTime}
                </div>
                <div className="col-3 text-center">
                    Rona: {RonaUpdateTime}
                </div>
                <div className="col-3 text-center">
                    Ahlat: {AhlatUpdateTime}
                </div>
                <div className="col-3 text-center">
                    Harem: {HaremUpdateTime}
                </div>
            </div>
            
            <div className="theme-switch">
                
            </div>

            <div className="container-fluid">
                <div className="row pt-4 justify-content-between">
                    <Card title="KGRTRY"
                        type="MainTable"
                        toFixedSize={4}
                        data={
                            [
                                [
                                    ['Ozbey', OzbeySatAltinTL],
                                    ['Rona', RonaAlAltinTL],
                                    ['Ahlat', AhlatAlAltinTL],
                                    ['Harem', HaremAlAltinTL],
                                ],
                                [
                                    ['Ozbey', OzbeyAlAltinTL],
                                    ['Rona', RonaSatAltinTL],
                                    ['Ahlat', AhlatSatAltinTL],
                                    ['Harem', HaremSatAltinTL],
                                ]
                            ]
                        }
                    />
                    <Card title="KGRUSD"
                        type="MainTable"
                        data={
                            [
                                [
                                    ['Ozbey', OzbeySatAltinDolar],
                                    ['Rona', RonaAlAltinDolar],
                                    // ['Ahlat', AhlatAlAltinDolar],
                                    ['Harem', HaremAlAltinDolar],
                                ],
                                [
                                    ['Ozbey', OzbeyAlAltinDolar],
                                    ['Rona', RonaSatAltinDolar],
                                    // ['Ahlat', AhlatSatAltinDolar],
                                    ['Harem', HaremSatAltinDolar],
                                ]
                            ]
                        }
                        // setMinValueFunction={setMinKGRUSD}
                    />
                    <Card title="KGREUR"
                        type="MainTable"
                        data={
                            [
                                [
                                    // ['Ozbey', OzbeySatAltinEuro],
                                    ['Rona', RonaAlAltinEuro],
                                    // ['Ahlat', AhlatAlAltinEuro],
                                    ['Harem', HaremAlAltinEuro],
                                ],
                                [
                                    // ['Ozbey', OzbeyAlAltinEuro],
                                    ['Rona', RonaSatAltinEuro],
                                    // ['Ahlat', AhlatSatAltinEuro],
                                    ['Harem', HaremSatAltinEuro],
                                ]
                            ]
                        }
                        // setMinValueFunction={setMinKGREUR}
                    />
                    <Card title="USDTRY"
                        type="MainTable"
                        data={
                            [
                                [
                                    ['Ozbey', OzbeySatDolarTl],
                                    ['Rona', RonaAlDolarTl],
                                    ['Ahlat', AhlatAlDolarTl],
                                    ['Harem', HaremAlDolarTl],
                                ],
                                [
                                    ['Ozbey', OzbeyAlDolarTl],
                                    ['Rona', RonaSatDolarTl],
                                    ['Ahlat', AhlatSatDolarTl],
                                    ['Harem', HaremSatDolarTl],
                                ]
                            ]
                        }
                        // setMinValueFunction={setMinUSDTRY}
                    />
                    <Card title="EURTRY"
                        type="MainTable"
                        data={
                            [
                                [
                                    ['Ozbey', OzbeySatEuroTl],
                                    ['Rona', RonaAlEuroTl],
                                    ['Ahlat', AhlatAlEuroTl],
                                    ['Harem', HaremAlEuroTl],
                                ],
                                [
                                    ['Ozbey', OzbeyAlEuroTl],
                                    ['Rona', RonaSatEuroTl],
                                    ['Ahlat', AhlatSatEuroTl],
                                    ['Harem', HaremSatEuroTl],
                                ]
                            ]
                        }
                        // setMinValueFunction={setMinEURTRY}
                    />
                    <Card title="EURUSD"
                        type="MainTable"
                        data={
                            [
                                [
                                    ['Ozbey', OzbeySatEuroDolar],
                                    ['Rona', RonaAlEuroDolar],
                                    ['Ahlat', AhlatAlEuroDolar],
                                    ['Harem', HaremAlEuroDolar],
                                ],
                                [
                                    ['Ozbey', OzbeyAlEuroDolar],
                                    ['Rona', RonaSatEuroDolar],
                                    ['Ahlat', AhlatSatEuroDolar],
                                    ['Harem', HaremSatEuroDolar],
                                ]
                            ]
                        }    
                    />
                    <Card title="CHFTRY"
                        type="MainTable"
                        data={
                            [
                                [
                                    // ['Ozbey', OzbeySatCHFTRY],
                                    // ['Rona', RonaAlCHFTRY],
                                    ['Ahlat', AhlatAlCHFTRY],
                                    ['Harem', HaremAlCHFTRY],
                                ],
                                [
                                    // ['Ozbey', OzbeyAlCHFTRY],
                                    // ['Rona', RonaSatCHFTRY],
                                    ['Ahlat', AhlatSatCHFTRY],
                                    ['Harem', HaremSatCHFTRY],
                                ]
                            ]
                        }    
                    />
                    <Card title="GBPTRY"
                        type="MainTable"
                        data={
                            [
                                [
                                    // ['Ozbey', OzbeySatGBPTRY],
                                    // ['Rona', RonaAlGBPTRY],
                                    ['Ahlat', AhlatAlGBPTRY],
                                    ['Harem', HaremAlGBPTRY],
                                ],
                                [
                                    // ['Ozbey', OzbeyAlGBPTRY],
                                    // ['Rona', RonaSatGBPTRY],
                                    ['Ahlat', AhlatSatGBPTRY],
                                    ['Harem', HaremSatGBPTRY],
                                ]
                            ]
                        }    
                    />

                    <Card title="TRY -> USD -> KGR"
                        type="TableForTransform"
                        data={ResultTRYtoUSDtoKGR}
                    />

                    <Card title="TRY -> EUR -> KGR"
                        type="TableForTransform"
                        data={ResultTRYtoEURtoKGR}
                    />
                    
                    {/* <Card title="TRY -> USD -> XAU" />
                    <Card title="TRY -> EUR -> XAU" /> */}
                </div>
            </div>
        </main>
    );
}

export default App;

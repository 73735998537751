import React, {useState, useEffect} from 'react';

function DataTable(props) {

    const [SortedData, setSortedData] = useState([]);

    useEffect(() => {
        
        if (props.type === 'alis') {
            props.data.sort(function (a, b) {
                if (a[1] >= b[1])
                    return -1;
                if (a[1] < b[1])
                    return 1;
            });
    
            setSortedData(props.data);
        } else {
            props.data.sort(function(a, b){
                return a[1] - b[1] // index 1 -> value 
            });
    
            // TRY USD/EUR KGR hesaplaması için minimum değer alma
            // if (props.setMinValueFunction) {
            //     props.setMinValueFunction(props.data[0]);
            // }
    
            setSortedData(props.data);
        }

    }, [props.data]);

    return (
        <div className="table-responsive">
            <table className="table table-dark table-hover">
                <tbody>
                    {
                        SortedData.map((element, index)=>{
                            return (
                                <tr key={index}>
                                    <td>{ element[0] /* index 0 -> company name */ }</td>
                                    <td>{ new Intl.NumberFormat('tr-TR', { maximumSignificantDigits: 8 }).format(element[1]) /* index 1 -> value */  }</td> 
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default DataTable;
